var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "multiselect-wrapper",
        { "not-empty": !!_vm.bsValue },
        _vm.required ? "required" : "",
        _vm.wrapperClasses,
      ],
    },
    [
      _c("label", { staticClass: "input-group-material-label" }, [
        _vm._v(_vm._s(_vm.label) + " "),
        _vm.required
          ? _c("span", { staticClass: "required-asterix" }, [_vm._v("*")])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm._t("addNew", function () {
        return [
          _vm.addNew && _vm.hasPermission(_vm.neededPermission)
            ? _c(
                "div",
                { staticClass: "add-new-link" },
                [
                  _c(
                    "base-button",
                    {
                      attrs: { type: "button", design: "link", size: "sm" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push({ name: _vm.addNew })
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("general.add_new")))]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      }),
      _vm._v(" "),
      _vm.addNewModal && _vm.hasPermission(_vm.neededPermission)
        ? _c(
            "div",
            { staticClass: "add-new-link" },
            [
              _c(
                "base-button",
                {
                  attrs: { type: "button", design: "link", size: "sm" },
                  on: { click: _vm.toggleNewModal },
                },
                [_vm._v(_vm._s(_vm.$t("general.add_new")))]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.addNewModal &&
      _vm.hasPermission(_vm.neededPermission) &&
      _vm.showNewModel
        ? _c(
            "app-sidebar-modal",
            {
              on: {
                close: function ($event) {
                  return _vm.toggleNewModal(true)
                },
              },
            },
            [_vm._t("addNewModal")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$scopedSlots.selectedOption && _vm.$scopedSlots.listOption
        ? [
            _c("multiselect", {
              ref: `multiselect${_vm.cbId}`,
              class: ["input-group-material", _vm.inputClasses],
              attrs: {
                options: _vm.options,
                placeholder: _vm.label,
                "track-by": _vm.trackBy,
                label: _vm.showBy,
                "custom-label": _vm.customShowBy,
                searchable: _vm.searchable,
                "show-labels": false,
                "allow-empty": _vm.allowEmpty,
                "group-values": _vm.groupValues,
                "group-label": _vm.groupLabel,
                "group-select": _vm.groupSelect,
                multiple: _vm.multiple,
                "close-on-select": _vm.closeOnSelect,
                disabled: _vm.disabled,
                loading: _vm.loading,
                limit: _vm.limit,
                max: _vm.multiple ? _vm.max : false,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "singleLabel",
                    fn: function ({ option }) {
                      return [
                        _vm._t("selectedOption", null, { option: option }),
                      ]
                    },
                  },
                  {
                    key: "option",
                    fn: function ({ option }) {
                      return [_vm._t("listOption", null, { option: option })]
                    },
                  },
                ],
                null,
                true
              ),
              model: {
                value: _vm.bsValue,
                callback: function ($$v) {
                  _vm.bsValue = $$v
                },
                expression: "bsValue",
              },
            }),
          ]
        : [
            _c("multiselect", {
              ref: `multiselect${_vm.cbId}`,
              class: ["input-group-material", _vm.inputClasses],
              attrs: {
                options: _vm.options,
                placeholder: _vm.label,
                "track-by": _vm.trackBy,
                label: _vm.showBy,
                "custom-label": _vm.callCustomShowBy,
                searchable: _vm.searchable,
                "show-labels": false,
                "allow-empty": _vm.allowEmpty,
                "group-values": _vm.groupValues,
                "group-label": _vm.groupLabel,
                "group-select": _vm.groupSelect,
                multiple: _vm.multiple,
                "close-on-select": _vm.closeOnSelect,
                disabled: _vm.disabled,
                loading: _vm.loading,
                limit: _vm.limit,
                max: _vm.multiple ? _vm.max : false,
              },
              model: {
                value: _vm.bsValue,
                callback: function ($$v) {
                  _vm.bsValue = $$v
                },
                expression: "bsValue",
              },
            }),
          ],
      _vm._v(" "),
      _vm._t("errorBlock", function () {
        return [
          _vm.error
            ? _c(
                "div",
                {
                  staticClass: "text-danger invalid-feedback",
                  staticStyle: { display: "block", "margin-top": "-0.5rem" },
                },
                [
                  _vm._v(
                    "\n                " + _vm._s(_vm.error) + "\n            "
                  ),
                ]
              )
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }