var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "base-view view-paragraph" },
    [
      _c(
        "div",
        {
          staticClass: "view-group",
          class: [
            { "has-label": _vm.label || _vm.$slots.label },
            { "has-success": _vm.valid === true },
            { "has-danger": _vm.error || _vm.valid === false },
            { "not-empty": _vm.value || _vm.value === 0 },
          ],
        },
        [
          _vm._t("label", function () {
            return [
              _vm.label
                ? _c("label", { class: _vm.labelClasses }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.label) +
                        "\n                "
                    ),
                  ])
                : _vm._e(),
            ]
          }),
          _vm._v(" "),
          _vm._t("main", function () {
            return [
              _vm.html
                ? _c("div", {
                    class: [
                      "view-data",
                      { "is-valid": _vm.valid === true },
                      { "is-invalid": _vm.valid === false },
                      _vm.dataClasses,
                    ],
                    domProps: { innerHTML: _vm._s(_vm.value) },
                  })
                : _c(
                    "p",
                    {
                      class: [
                        "view-data",
                        { "is-valid": _vm.valid === true },
                        { "is-invalid": _vm.valid === false },
                        _vm.dataClasses,
                      ],
                    },
                    [_vm._v(_vm._s(_vm.value || "-"))]
                  ),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm._t("infoBlock"),
      _vm._v(" "),
      _vm._t("errorBlock", function () {
        return [
          _vm.error
            ? _c(
                "div",
                {
                  staticClass: "text-danger invalid-feedback",
                  staticStyle: { display: "block" },
                },
                [
                  _vm._v(
                    "\n                " + _vm._s(_vm.error) + "\n            "
                  ),
                ]
              )
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }