var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.hideIfEmpty ||
    (_vm.hideIfEmpty && _vm.value !== null && _vm.value !== "")
    ? _c(
        "div",
        { staticClass: "base-view view-single" },
        [
          _c(
            "div",
            {
              staticClass: "view-group",
              class: [
                { "has-label": _vm.label || _vm.$slots.label },
                { "has-success": _vm.valid === true },
                { "has-danger": _vm.error || _vm.valid === false },
                { "not-empty": _vm.value || _vm.value === 0 },
              ],
            },
            [
              _vm._t("label", function () {
                return [
                  _vm.label
                    ? _c("label", { class: _vm.labelClasses }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.label) +
                            "\n                "
                        ),
                      ])
                    : _vm._e(),
                ]
              }),
              _vm._v(" "),
              _vm._t("default", function () {
                return [
                  _vm.type !== "badge"
                    ? [
                        _vm.value && _vm.value.length
                          ? _c(
                              _vm.tag,
                              {
                                tag: "component",
                                class: [
                                  "view-data",
                                  { "is-valid": _vm.valid === true },
                                  { "is-invalid": _vm.valid === false },
                                  _vm.dataClasses,
                                  _vm.computedClasses,
                                ],
                              },
                              [
                                _vm.limit
                                  ? _vm._l(
                                      _vm.value.slice(0, _vm.limit),
                                      function (v) {
                                        return _c(
                                          "span",
                                          {
                                            key: v[_vm.id],
                                            class: _vm.itemClasses,
                                          },
                                          [_vm._v(_vm._s(v[_vm.name]))]
                                        )
                                      }
                                    )
                                  : _vm._l(_vm.value, function (v) {
                                      return _c(
                                        "span",
                                        {
                                          key: v[_vm.id],
                                          class: _vm.itemClasses,
                                        },
                                        [_vm._v(_vm._s(v[_vm.name]))]
                                      )
                                    }),
                                _vm._v(" "),
                                _vm._t("addOnRight"),
                              ],
                              2
                            )
                          : _c("span", [_vm._v("-")]),
                      ]
                    : [
                        _vm.value && _vm.value.length
                          ? _c(
                              _vm.tag,
                              {
                                tag: "component",
                                class: [
                                  "view-data",
                                  { "is-valid": _vm.valid === true },
                                  { "is-invalid": _vm.valid === false },
                                  _vm.dataClasses,
                                  _vm.computedClasses,
                                ],
                              },
                              [
                                _vm.limit
                                  ? _vm._l(
                                      _vm.value.slice(0, _vm.limit),
                                      function (v) {
                                        return _c(
                                          "badge",
                                          {
                                            key: v[_vm.id],
                                            class: _vm.itemClasses,
                                            attrs: { type: _vm.badgeType },
                                          },
                                          [_vm._v(_vm._s(v[_vm.name]))]
                                        )
                                      }
                                    )
                                  : _vm._l(_vm.value, function (v) {
                                      return _c(
                                        "badge",
                                        {
                                          key: v[_vm.id],
                                          class: _vm.itemClasses,
                                          attrs: { type: _vm.badgeType },
                                        },
                                        [_vm._v(_vm._s(v[_vm.name]))]
                                      )
                                    }),
                                _vm._v(" "),
                                _vm._t("addOnRight"),
                              ],
                              2
                            )
                          : _c("span", [_vm._v("-")]),
                      ],
                ]
              }),
            ],
            2
          ),
          _vm._v(" "),
          _vm._t("infoBlock"),
          _vm._v(" "),
          _vm._t("errorBlock", function () {
            return [
              _vm.error
                ? _c(
                    "div",
                    {
                      staticClass: "text-danger invalid-feedback",
                      staticStyle: { display: "block" },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.error) +
                          "\n            "
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }