var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vue-circular-progress" },
    [
      _c("div", { staticClass: "circle" }, [
        _c(
          "svg",
          {
            staticClass: "circle__svg",
            attrs: { width: _vm.circleSize, height: _vm.circleSize },
          },
          [
            _c("circle", {
              staticClass: "circle__progress circle__progress--path",
              style: {
                "stroke-width": _vm.strokeWidth,
                stroke: _vm.strokeColor,
              },
              attrs: { cx: _vm.centralP, cy: _vm.centralP, r: _vm.radius },
            }),
            _vm._v(" "),
            _c("circle", {
              staticClass: "circle__progress circle__progress--fill",
              style: _vm.fileStyl,
              attrs: { cx: _vm.centralP, cy: _vm.centralP, r: _vm.radius },
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "percent" },
          [
            _vm._t("default", function () {
              return [
                _c("span", { staticClass: "percent__int" }, [
                  _vm._v(_vm._s(_vm.value)),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "percent_sign" }, [_vm._v("%")]),
              ]
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _vm._t("footer"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }