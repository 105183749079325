import { getOptionRoutes } from './config/module-config'

export default [{
        path: 'course',
        name: 'appCourse',
        redirect: { name: 'appCourse' },
        meta: {
            title: 'course.courses',
            icon: 'fas fa-book',
            permissions: ['list-course', 'create-course', 'edit-course','list-category','list-order'],
        },
        component: {
            template: '<router-view></router-view>'
        },
        
        children: [{
                path: '',
                name: 'appCourse',
                meta: {
                    title: 'course.listcourses',
                    permissions: ['list-course']
                },
                component: () => import('@views/app/course/course' /* webpackChunkName: "js/app/course/course" */ ),
            },
            {
                path: 'overview',
                name: 'courseCourse',
                meta: {
                    title: 'course.overview',
                    isHiddenNav: true,
                    isNotNav: true,
                    permissions: ['list-course']
                },
                component: () => import('@views/app/course/create-course' /* webpackChunkName: "js/app/course/start" */ ),
            },
            {
                path: 'list-chapters',
                name: 'CourseChapters',
                meta: {
                    title: 'course.listchapters', 
                    isHiddenNav: true,
                    isNotNav: true,
                    permissions: ['list-chapter']
                },
                component: () => import('@views/app/course/config/CourseChapters'  /* webpackChunkName: "js/app/course/start" */ ),
            },
            {
                path: 'list-tests',
                name: 'ChaptersTests',
                meta: {
                    title: 'course.listchapterstests', 
                    isHiddenNav: true,
                    isNotNav: true,
                    permissions: ['list-test']
                },
                component: () => import('@views/app/course/modules/ChaptersTests'  /* webpackChunkName: "js/app/course/start" */ ),
            },
            {
                path: 'list-questions',
                name: 'ChapterQuestions',
                meta: {
                    title: 'course.listchapterquestions', 
                    isHiddenNav: true,
                    isNotNav: true,
                    permissions: ['list-test-question']
                },
                component: () => import('@views/app/course/modules/ChapterQuestions'  /* webpackChunkName: "js/app/course/start" */ ),
            },
            {
                path: 'list-answers',
                name: 'ChaptersAnswers',
                meta: {
                    title: 'course.listchaptersanswers', 
                    isHiddenNav: true,
                    isNotNav: true,
                    permissions: ['list-test-answer']
                },
                component: () => import('@views/app/course/modules/ChaptersAnswers'  /* webpackChunkName: "js/app/course/start" */ ),
            },
            {
                path: 'list-quiz',
                name: 'ChaptersQuiz',
                meta: {
                    title: 'course.listchaptersquiz', 
                    isHiddenNav: true,
                    isNotNav: true,
                    permissions: ['list-quiz']
                },
                component: () => import('@views/app/course/modules/ChaptersQuiz'  /* webpackChunkName: "js/app/course/start" */ ),
            },
            {
                path: 'quiz-test',
                name: 'QuizTest',
                meta: {
                    title: 'course.quiztest', 
                    isHiddenNav: true,
                    isNotNav: true,
                    permissions: ['appear-test-quiz']
                },
                component: () => import('@views/app/course/modules/QuizTest'  /* webpackChunkName: "js/app/course/start" */ ),
            },
            {
                path: 'list-quiz-questions',
                name: 'ChaptersQuizQuestions',
                meta: {
                    title: 'course.listchaptersquizquestions', 
                    isHiddenNav: true,
                    isNotNav: true,
                    permissions: ['list-quiz-questions']
                },
                component: () => import('@views/app/course/modules/ChaptersQuizQuestions'  /* webpackChunkName: "js/app/course/start" */ ),
            },
            {
                path: 'list-quiz-results',
                name: 'ChaptersQuizResults',
                meta: {
                    title: 'course.listchaptersquizresults', 
                    isHiddenNav: true,
                    isNotNav: true,
                    permissions: ['check-result-quiz']
                },
                component: () => import('@views/app/course/modules/ChaptersQuizResults'  /* webpackChunkName: "js/app/course/start" */ ),
            },
            {
                path: 'list-assignments',
                name: 'ChaptersAssignments',
                meta: {
                    title: 'course.listchaptersassignments', 
                    isHiddenNav: true,
                    isNotNav: true,
                    permissions: ['list-assignments']
                },
                component: () => import('@views/app/course/modules/ChaptersAssignments'  /* webpackChunkName: "js/app/course/start" */ ),
            },
            {
                path: 'list-submitted-assignments',
                name: 'ChaptersSubmittedAssignments',
                meta: {
                    title: 'course.listchaptersSubmittedAssignments', 
                    isHiddenNav: true,
                    isNotNav: true,
                    permissions: ['list-plan']
                },
                component: () => import('@views/app/course/modules/ChaptersSubmittedAssignments'  /* webpackChunkName: "js/app/course/start" */ ),
            },
            {
                path: 'list-content',
                name: 'ChaptersContent',
                meta: {
                    title: 'course.listchapterscontent', 
                    isHiddenNav: true,
                    isNotNav: true,
                    permissions: ['list-chapter-content']
                },
                component: () => import('@views/app/course/modules/ChaptersContent'  /* webpackChunkName: "js/app/course/start" */ ),
            },
            {
                path: 'list-teachers',
                name: 'courseTeachers',
                meta: {
                    title: 'course.listCourseTeachers', 
                    isHiddenNav: true,
                    isNotNav: true,
                    permissions: ['list-teacher']
                },
                component: () => import('@views/app/course/config/Teachers'  /* webpackChunkName: "js/app/course/start" */ ),
            },
            {
                path: 'list-students',
                name: 'courseStudents',
                meta: {
                    title: 'course.listcourseStudents', 
                    isHiddenNav: true,
                    isNotNav: true,
                    permissions: ['list-student']
                },
                component: () => import('@views/app/course/config/Students'  /* webpackChunkName: "js/app/course/start" */ ),
            },
            {
                path: 'list-plan',
                name: 'plans',
                meta: {
                    title: 'course.listplans', 
                    permissions: ['list-plan']
                },
                component: () => import('@views/app/course/config/Plan'  /* webpackChunkName: "js/app/course/start" */ ),
            }, {
                path: 'course-overview',
                name: 'courseOverview',
                meta: {
                    isHiddenNav: true,
                    isNotNav: true,
                    title: 'course.courseoverview', 
                    permissions: ['list-course']
                },
                component: () => import('@views/app/course/config/Overview'  /* webpackChunkName: "js/app/course/start" */ ),
            },
            {
                path: 'course-detail',
                name: 'courseDetail',
                meta: {
                    isHiddenNav: true,
                    isNotNav: true,
                    title: 'course.courseDetail', 
                    permissions: ['edit-course']
                },
                component: () => import('@views/app/course/config/Course.vue'  /* webpackChunkName: "js/app/course/start" */ ),
            },
            {
                path: 'course-plan',
                name: 'courseplan',
                meta: {
                    isHiddenNav: true,
                    isNotNav: true,
                    title: 'course.coursePlan', 
                    permissions: ['list-courseplan']
                },
                component: () => import('@views/app/course/config/CoursePlan.vue'  /* webpackChunkName: "js/app/course/start" */ ),
            },
            {
                path: 'liveclass',
                name: 'liveClass',
                meta: {
                    title: 'course.liveclass',
                    isHiddenNav: true,
                    isNotNav: true,
                    permissions: ['list-liveclass']
                },
                component: () => import('@views/app/course/config/live-class'  /* webpackChunkName: "js/app/course/start" */ ),
            },
            {
                path: 'list-categories',
                name: 'appCategoriesList',
                meta: {
                    title: 'category.categories',
                    permissions: ['list-category']
                },
                component: () => import('@views/app/course/category' /* webpackChunkName: "js/app/category/categories" */ ),
            }
        ]
    },
    {
        path: 'order',
        name: 'appOrder',
        redirect: { name: 'appOrderList' },
        meta: {
            title: 'course.order',
            icon: 'fas fa-shopping-cart',
            hideChildren: true,
            permissions: ['list-order'],
        },
        component: {
            template: '<router-view></router-view>'
        },
        children: [{
                path: '',
                name: 'appOrderList',
                meta: {
                    trans: 'global.list',
                    title: 'course.order',
                    permissions: ['list-order']
                },
                component: () => import('@views/app/course/order' /* webpackChunkName: "js/app/course/order" */ ),
            }
        ]
    },
]

